<template>
  <div>
    <p>coucou</p>
    <calc-resist/>
    <p>coucou</p>
    <calc-resist/>
  </div>
</template>

<script>
import CalcResist from '../components/CalcResist.vue';

export default {
  components: { CalcResist },
  name: 'test',
};

</script>
